.billBalance{
    float:right;
}
.paidDetails{
    display: flex;
    float: right;
}
.addAttachments{
    min-height:118px !important;
}
.dateField{
    padding-top:0 !important;
}